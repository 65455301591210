<template>
  <div class="content" id="listing-content">
    <div class="main-wrapper">
      <v-row
        style="width: 100%; gap: 12px; padding-top: 24px; padding-bottom: 24px"
      >
        <button
          @click="$router.push('/listings/add/${this.$route.params.id}')"
          class="outlined-small-button"
        >
          <img src="../../../assets/images/plusSign.svg" alt="" />
          {{ $t("AddListing") }}
        </button>
        <button
          @click="openLeads"
          v-bind:class="{
            'outlined-small-button semi-bold white-background': isLeadsOpen,
            'outlined-small-button': !isLeadsOpen,
          }"
        >
          <img src="../../../assets/images/leads.svg" alt="" />{{
            $t("ShowContacts")
          }}
        </button>
      </v-row>
      <div v-if="listings.length < 1" class="no-results">
        <h3>{{ $t("NoListings") }}</h3>
      </div>
      <v-row class="customer-row">
        <v-row
          v-for="(listing, index) in listings"
          :key="listing._id"
          class="object-row"
        >
          <div
            @dragover="allowDrop($event)"
            @drop="
              drop($event, listing._id, listing.street, listing.houseNumber, 0)
            "
            @click="collapseBoard(index)"
            class="listing-title-row"
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 16px;
              "
            >
              <h5 class="listing-title">
                {{ listing.street }} {{ listing.houseNumber }}
              </h5>
              <p class="leads-count">{{ numberOfLeads(listing.funnels) }}</p>
            </div>
            <div>
              <img
                :id="index + 'down'"
                src="../../../assets/images/collapse-down.svg"
                alt=""
              />
              <img
                style="display: none"
                :id="index + 'up'"
                src="../../../assets/images/collapse-up.svg"
                alt=""
              />
            </div>
          </div>
          <v-row class="columns-wrapper" :id="index + 'column'">
            <div
              v-for="(value, propertyName) in listing.funnels"
              :key="JSON.stringify(propertyName + index)"
              class="column-width"
              @dragover="allowDrop($event)"
              @drop="
                drop(
                  $event,
                  listing._id,
                  listing.street,
                  listing.houseNumber,
                  propertyName
                )
              "
            >
              <v-row justify="space-between">
                <p class="content-small grey900">
                  {{ propertyName }}
                </p>
                <img src="../../../assets/images/arrowNext.svg" alt="" />
              </v-row>

              <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
              <draggable
                :list="value"
                :animation="200"
                ghost-class="ghost-card"
                group="tasks"
                @change="setMovedItem"
                @end="move"
                :id="propertyName"
                class="scrollable"
              >
                <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
                <task-card
                  @leadClicked="openLead"
                  :from-project="true"
                  v-for="task in value"
                  :lead-added="leadAdded"
                  :key="task._id"
                  :task="task"
                  :id="task._id"
                  class="mt-3 cursor-move"
                ></task-card>
                <!-- </transition-group> -->
              </draggable>
            </div>
          </v-row>
        </v-row>
      </v-row>
      <!-- <BoardLeads
        :from-project="true"
        @leadClicked="openLead"
        :leads="leads"
        :is-open="isLeadsOpen"
      /> -->
      <ClientSidepanel
        @panelClosed="leadPanelOpen = false"
        :customer-data="clickedLead.leadId"
        :customer-data-leads-list="clickedLead.customerId"
        @funnelRemoved="getDealListings($route.params.id)"
        v-if="leadPanelOpen"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable-rp";
import TaskCard from "../../Listings/TaskCard.vue";
import ClientSidepanel from "@/components/Kliendiregister/ClientSidepanel.vue";

export default {
  name: "BoardContent",
  components: {
    draggable,
    TaskCard,
    ClientSidepanel,
  },
  data() {
    return {
      leadPanelOpen: false,
      clickedLead: null,
      leadAdded: 1,
      movedElement: null,
      isLoaded: false,
      arranged: false,
      leads: null,
      isLeadsOpen: false,
      listings: null,
      listingColumns: [],
    };
  },
  created() {
    this.getDealListings(this.$route.params.id);
    this.getLeads();
  },
  mounted() {
    const open = setInterval(() => {
      if (this.listings) {
        this.openLeads();
        this.openBoardsWithContent();
        clearInterval(open);
      }
    }, 200);
  },
  computed: {},

  methods: {
    openLead(lead) {
      if (lead.module && lead.module === "projects") {
        this.$router.push({
          name: "DealDetail",
          params: { id: lead.leadId },
        });
      } else {
        this.leadPanelOpen = true;
        this.clickedLead = lead;
      }
    },
    numberOfLeads(leads) {
      let leadsLength = 0;
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(leads)) {
        value.forEach(() => {
          leadsLength++;
        });
      }

      if (leadsLength === 1) {
        return leadsLength + ` ${this.$t("Lead")}`;
      } else {
        return leadsLength + ` ${this.$t("NumerableLeads")}`;
      }
    },
    async getDealListings(id) {
      const resp = await axios.get(`/api/project/listings/${id}`);
      this.listings = resp.data.data;
    },
    openBoardsWithContent() {
      let openables = [];
      this.listings.forEach((listing, index) => {
        Object.keys(listing.funnels).forEach((key) => {
          let leadsLength = listing.funnels[key].length;
          if (leadsLength > 0) {
            openables.push(index);
          }
        });
      });
      openables = [...new Set(openables)];
      openables.forEach((o) => {
        this.collapseBoard(o);
      });
    },
    allowDrop(ev) {
      ev.preventDefault();
    },
    async drop(ev, listingId, listingStreet, listingHouseNr, propertyName) {
      ev.preventDefault();
      let leadId = ev.dataTransfer.getData("lead");
      if (!leadId || leadId === "") {
        return;
      }
      let newFunnel2 = null;
      let listingTitle = listingStreet + " " + listingHouseNr;
      if (propertyName === 0) {
        newFunnel2 = {
          leadId,
          step: "Interested",
          listingId: listingId,
          listingName: listingTitle,
        };
      } else {
        newFunnel2 = {
          leadId,
          step: propertyName,
          listingId: listingId,
          listingName: listingTitle,
        };
      }

      const response1 = await axios.post(
        `/api/contact/leads/${leadId}`,
        newFunnel2
      );
      const response2 = await axios.patch(
        `/api/listing/${listingId}`,
        newFunnel2
      );
      if (response1.status === 200 && response2.status === 200) {
        await this.getDealListings(this.$route.params.id);
        this.listings = this.filteredListings;
        this.leadAdded++;
      }
    },
    openLeads() {
      this.isLeadsOpen = !this.isLeadsOpen;
      let listingHeader = document.getElementById("listing-header");
      let listingContent = document.getElementById("listing-content");
      let sidepanelLeads = document.getElementById("sidepanel-leads");
      if (this.isLeadsOpen) {
        listingHeader.classList.remove("slide-animation-close");
        listingContent.classList.remove("slide-animation-close");
        sidepanelLeads.classList.remove("slide-close");
        listingHeader.classList.add("slide-animation-open");
        listingContent.classList.add("slide-animation-open");
        sidepanelLeads.classList.add("slide-open");
      } else {
        listingHeader.classList.remove("slide-animation-open");
        listingContent.classList.remove("slide-animation-open");
        sidepanelLeads.classList.remove("slide-open");
        listingHeader.classList.add("slide-animation-close");
        listingContent.classList.add("slide-animation-close");
        sidepanelLeads.classList.add("slide-close");
      }
    },
    collapseBoard(el) {
      let collapsedElement = document.getElementById(el + "column");
      let arrowUp = document.getElementById(el + "up");
      let arrowDown = document.getElementById(el + "down");
      if (collapsedElement.style.display !== "flex") {
        collapsedElement.style.display = "flex";
        arrowUp.style.display = "block";
        arrowDown.style.display = "none";
      } else {
        collapsedElement.style.display = "none";
        arrowUp.style.display = "none";
        arrowDown.style.display = "block";
      }
    },
    setMovedItem(evt) {
      if (evt.removed) {
        this.movedElement = evt.removed.element;
      }
    },
    async move(evt) {
      let changedLead = this.movedElement;

      switch (evt.to.id) {
        case "Interested":
          changedLead.step = "Interested";
          break;
        case "Huvitatud":
          changedLead.step = "Interested";
          break;
        case "Viewed":
          changedLead.step = "Viewed";
          break;
        case "Vaadatud":
          changedLead.step = "Viewed";
          break;
        case "Negotiation & Contract":
          changedLead.step = "Negotiation & Contract";
          break;
        case "Läbirääkimised & Leping":
          changedLead.step = "Negotiation & Contract";
          break;
        case "Offers":
          changedLead.step = "Offers";
          break;
        case "Pakkumised":
          changedLead.step = "Offers";
          break;
        case "Lost / Archived":
          changedLead.step = "Lost / Archived";
          break;
        case "Kaotatud / Arhiveeritud":
          changedLead.step = "Lost / Archived";
          break;
        case "Soovitused":
          changedLead.step = "Matches";
          break;
      }

      if (changedLead.step === "Matches") {
        changedLead.step = evt.from.id;

        await axios.post(
          `/api/contact/leads/delete/${changedLead.leadId}`,
          changedLead
        );
        await axios.post(
          `/api/listing/leads/delete/${changedLead.listingId}`,
          changedLead
        );
        return;
      }

      let listingId = changedLead.listingId;
      changedLead.previousStep = evt.from.id;
      let listing = this.listing;
      let listingTitleObject = {
        street: listing.street,
        houseNumber: listing.houseNumber,
        district: listing.district,
        cityPart: listing.cityPart,
        city: listing.city,
        county: listing.county,
      };

      listingTitleObject = Object.fromEntries(
        Object.entries(listingTitleObject).filter(
          //eslint-disable-next-line no-unused-vars
          ([_, v]) => v != null && v != "" && v != "undefined"
        )
      );
      let listingTitle = "";
      for (const [key, value] of Object.entries(listingTitleObject)) {
        if (key && value != null && value != "" && value != "undefined") {
          listingTitle = listingTitle + value + ", ";
        }
      }

      let titleEnd = listingTitle.slice(listingTitle.length - 2);
      if (titleEnd === ", ") {
        listingTitle = listingTitle.slice(0, -2);
      }
      changedLead.listingName = listingTitle;
      const response1 = await axios.patch(
        `/api/contact/leads/${changedLead.leadId}`,
        changedLead
      );
      const response2 = await axios.patch(
        `/api/listing/${listingId}`,
        changedLead
      );
      console.log(response2, response1);

      /*await this.getListings();*/
    },
    async getLeads() {
      const leadResponse = await axios.get("/api/leads");
      this.leads = leadResponse.data.customers;
    },
  },
  watch: {
    /*listings() {
      this.openBoardsWithContent();
    },*/
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: block;
  padding-left: 104px;
  padding-right: 88px;
  min-height: 600px;
  background-color: #f4f5f7;
}
.main-wrapper {
  display: flex;
  background-color: #f4f5f7;
  overflow-x: scroll;
  flex-wrap: nowrap;
  flex-direction: column;
  padding-bottom: 10vh;
}
.col {
  padding: 0;
}
.column-width {
  min-width: 208px;
  max-width: 208px;
}
.customer-row {
  margin-bottom: 8px;
  background-color: transparent;
}
.leads-count {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  padding-top: 2px;
  text-transform: capitalize;
  color: #75787a !important;
}
.object-row {
  background: #ffff;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  margin-top: 16px;
}
.listing-title-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.columns-wrapper {
  gap: 24px;
  margin-top: 28px;
  display: none;
  flex-wrap: nowrap;
  overflow-x: scroll;
}
.white-background {
  background-color: #ffffff;
}
#listing-header,
#listing-content {
  transition: width, margin-left 300ms ease-in-out;
}
.slide-animation {
  margin-left: 256px !important;
}
.slide-animation-close {
  margin-left: 0 !important;
}
.ghost-card {
  background-color: #c1c1c1 !important;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
}
.scrollable {
  min-height: 48px;
}
.slide-animation-open {
  margin-left: 256px !important;
}
.slide-animation-close {
  margin-left: 0 !important;
}
</style>
