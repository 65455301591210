<template>
  <div :key="leadAdded" class="task-wrapper" @click="openLead()">
    <div class="first-row">
      <img
        v-if="task.module && task.module === 'projects'"
        :src="require('@/assets/images/projects.svg')"
        alt=""
      />
      <p class="lead-name">{{ task.leadName }}</p>
    </div>
    <div class="user-row" v-if="task.module && task.module === 'projects'">
      <div class="broker-info">
        <p
          class="button-small hoverable-info"
          @click.stop="openBrokerDetailPanel($event, task.creator)"
        >
          {{ task.creator.name }}
        </p>
      </div>
    </div>
    <BrokerInfoPanel
      v-if="brokerDetailOpened"
      :item="clickedBroker"
      @closed="() => (brokerDetailOpened = false)"
      :position="brokerPanelPosition"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import BrokerInfoPanel from "@/components/common/BrokerInfoPanel.vue";
export default {
  components: {
    BrokerInfoPanel,
  },
  data() {
    return {
      isLoaded: false,
      brokerDetailOpened: false,
      brokerPanelPosition: null,
      clickedBroker: null,
    };
  },
  props: {
    leadAdded: {
      type: Number,
    },
    task: {
      type: Object,
      default: () => ({}),
    },
    fromProject: {
      type: Boolean,
      default: false,
    },
  },
  async created() {},
  computed: {},
  methods: {
    ...mapActions(["setClientSidepanel"]),
    openLead() {
      if (this.fromProject) {
        this.$emit("leadClicked", this.task);
      } else {
        this.setClientSidepanel();
        this.$router
          .push({
            name: "LeadDetailProject",
            params: { id: this.task.leadId },
          })
          .then(() => {});
      }
    },
    openBrokerDetailPanel(event, person) {
      this.brokerPanelPosition = {
        left: event.clientX + "px",
        top: event.clientY + "px",
      };
      this.brokerDetailOpened = true;
      this.clickedBroker = person;
    },
  },
};
</script>
<style lang="scss" scoped>
.task-wrapper {
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid #e6e8ec;
  cursor: pointer;
  width: 100%;
}
.first-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  gap: 8px;
  margin-bottom: 4px;
  color: #75787a;
  p {
    margin-bottom: 0 !important;
  }
  img {
    width: 16px;
    height: 16px;
  }
}
.task-name {
  font-family: Inter;
  font-style: normal;
  color: black;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}
.dot {
  height: 5px;
  width: 5px;
  background-color: #939597;
  border-radius: 50%;
  align-self: center;
  margin-left: 8px;
  margin-right: 8px;
}
.object-row {
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* grey/grey950 */

  color: #75787a;
  img {
    color: #75787a;
  }
  p {
    margin-bottom: 0 !important;
  }
}
.date {
  justify-content: flex-end;
}
.filtered {
  filter: invert(51%) sepia(7%) saturate(155%) hue-rotate(161deg)
    brightness(89%) contrast(88%);
}

.object-link:hover {
  color: black;
}
.row {
  margin: 8px !important;
}
.lead-name {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #000000;
}
.broker-info {
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 8px;
}
.hoverable-info {
  cursor: pointer;
  background-color: #f4f5f7;
  padding: 2px 4px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #ff5c01;
    color: #ffffff;
    font-weight: bold;
  }
}
.user-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
