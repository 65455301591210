var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content",
    attrs: {
      "id": "listing-content"
    }
  }, [_c('div', {
    staticClass: "main-wrapper"
  }, [_c('v-row', {
    staticStyle: {
      "width": "100%",
      "gap": "12px",
      "padding-top": "24px",
      "padding-bottom": "24px"
    }
  }, [_c('button', {
    staticClass: "outlined-small-button",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/listings/add/${this.$route.params.id}');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/images/plusSign.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("AddListing")) + " ")]), _c('button', {
    class: {
      'outlined-small-button semi-bold white-background': _vm.isLeadsOpen,
      'outlined-small-button': !_vm.isLeadsOpen
    },
    on: {
      "click": _vm.openLeads
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/images/leads.svg"),
      "alt": ""
    }
  }), _vm._v(_vm._s(_vm.$t("ShowContacts")) + " ")])]), _vm.listings.length < 1 ? _c('div', {
    staticClass: "no-results"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("NoListings")))])]) : _vm._e(), _c('v-row', {
    staticClass: "customer-row"
  }, _vm._l(_vm.listings, function (listing, index) {
    return _c('v-row', {
      key: listing._id,
      staticClass: "object-row"
    }, [_c('div', {
      staticClass: "listing-title-row",
      on: {
        "dragover": function ($event) {
          return _vm.allowDrop($event);
        },
        "drop": function ($event) {
          return _vm.drop($event, listing._id, listing.street, listing.houseNumber, 0);
        },
        "click": function ($event) {
          return _vm.collapseBoard(index);
        }
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "flex-direction": "row",
        "justify-content": "center",
        "gap": "16px"
      }
    }, [_c('h5', {
      staticClass: "listing-title"
    }, [_vm._v(" " + _vm._s(listing.street) + " " + _vm._s(listing.houseNumber) + " ")]), _c('p', {
      staticClass: "leads-count"
    }, [_vm._v(_vm._s(_vm.numberOfLeads(listing.funnels)))])]), _c('div', [_c('img', {
      attrs: {
        "id": index + 'down',
        "src": require("../../../assets/images/collapse-down.svg"),
        "alt": ""
      }
    }), _c('img', {
      staticStyle: {
        "display": "none"
      },
      attrs: {
        "id": index + 'up',
        "src": require("../../../assets/images/collapse-up.svg"),
        "alt": ""
      }
    })])]), _c('v-row', {
      staticClass: "columns-wrapper",
      attrs: {
        "id": index + 'column'
      }
    }, _vm._l(listing.funnels, function (value, propertyName) {
      return _c('div', {
        key: JSON.stringify(propertyName + index),
        staticClass: "column-width",
        on: {
          "dragover": function ($event) {
            return _vm.allowDrop($event);
          },
          "drop": function ($event) {
            return _vm.drop($event, listing._id, listing.street, listing.houseNumber, propertyName);
          }
        }
      }, [_c('v-row', {
        attrs: {
          "justify": "space-between"
        }
      }, [_c('p', {
        staticClass: "content-small grey900"
      }, [_vm._v(" " + _vm._s(propertyName) + " ")]), _c('img', {
        attrs: {
          "src": require("../../../assets/images/arrowNext.svg"),
          "alt": ""
        }
      })]), _c('draggable', {
        staticClass: "scrollable",
        attrs: {
          "list": value,
          "animation": 200,
          "ghost-class": "ghost-card",
          "group": "tasks",
          "id": propertyName
        },
        on: {
          "change": _vm.setMovedItem,
          "end": _vm.move
        }
      }, _vm._l(value, function (task) {
        return _c('task-card', {
          key: task._id,
          staticClass: "mt-3 cursor-move",
          attrs: {
            "from-project": true,
            "lead-added": _vm.leadAdded,
            "task": task,
            "id": task._id
          },
          on: {
            "leadClicked": _vm.openLead
          }
        });
      }), 1)], 1);
    }), 0)], 1);
  }), 1), _vm.leadPanelOpen ? _c('ClientSidepanel', {
    attrs: {
      "customer-data": _vm.clickedLead.leadId,
      "customer-data-leads-list": _vm.clickedLead.customerId
    },
    on: {
      "panelClosed": function ($event) {
        _vm.leadPanelOpen = false;
      },
      "funnelRemoved": function ($event) {
        return _vm.getDealListings(_vm.$route.params.id);
      }
    }
  }) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }