var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: _vm.leadAdded,
    staticClass: "task-wrapper",
    on: {
      "click": function ($event) {
        return _vm.openLead();
      }
    }
  }, [_c('div', {
    staticClass: "first-row"
  }, [_vm.task.module && _vm.task.module === 'projects' ? _c('img', {
    attrs: {
      "src": require('@/assets/images/projects.svg'),
      "alt": ""
    }
  }) : _vm._e(), _c('p', {
    staticClass: "lead-name"
  }, [_vm._v(_vm._s(_vm.task.leadName))])]), _vm.task.module && _vm.task.module === 'projects' ? _c('div', {
    staticClass: "user-row"
  }, [_c('div', {
    staticClass: "broker-info"
  }, [_c('p', {
    staticClass: "button-small hoverable-info",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.openBrokerDetailPanel($event, _vm.task.creator);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.task.creator.name) + " ")])])]) : _vm._e(), _vm.brokerDetailOpened ? _c('BrokerInfoPanel', {
    attrs: {
      "item": _vm.clickedBroker,
      "position": _vm.brokerPanelPosition
    },
    on: {
      "closed": function () {
        return _vm.brokerDetailOpened = false;
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }